
import { defineComponent, computed } from 'vue'
import PreChatting from '@/components/pages/chattingWidget/PreChatting.vue'
import ChattingMessages from '@/components/pages/chattingWidget/ChattingMessages.vue'
import ChattingCall from '@/components/pages/chattingWidget/ChattingCall.vue'
import ChattingWhatsAppDisabled from '@/components/pages/chattingWidget/ChattingWhatsAppDisabled.vue'
import ChattingWhatsApp from '@/components/pages/chattingWidget/ChattingWhatsApp.vue'
import ChattingWelcome from '@/components/pages/chattingWidget/ChattingWelcome.vue'
import ChattingPopovers from '@/components/pages/chattingWidget/chattingPopovers/ChattingPopovers.vue'
import ChattingSuccess from '@/components/pages/chattingWidget/ChattingSuccess.vue'
import {
  chatMessagesOnlyProps,
  chatWaitingProps,
  preChatProps,
  preEmailProps,
  preEmailSuccessProps,
  preTextProps,
  preTextSuccessProps
} from '@/definitions/chats/settings/web-widget/data'
import useWidget from '@/compositions/chatWidget'

type ChattingComponentType = {
  component: string;
  props?: any;
}

export default defineComponent({
  components: {
    ChattingCall,
    PreChatting,
    ChattingWelcome,
    ChattingMessages,
    ChattingPopovers,
    ChattingSuccess,
    ChattingWhatsApp,
    ChattingWhatsAppDisabled,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
  },
  setup() {
    const { currentState, showPopover } = useWidget()
    const chattingComponent = computed<ChattingComponentType>(() => {
      switch (currentState.value) {
        case 'preChat': return { component: 'pre-chatting', props: { ...preChatProps } }
        case 'email': return { component: 'pre-chatting', props: { ...preEmailProps } }
        case 'emailSuccess': return { component: 'chatting-success', props: { ...preEmailSuccessProps } }
        case 'makeCall': return { component: 'chatting-call' }
        case 'whatsapp': return { component: 'chatting-whats-app' }
        case 'whatsappDisabled': return { component: 'chatting-whats-app-disabled' }
        case 'preText': return { component: 'pre-chatting', props: { ...preTextProps } }
        case 'preTextSuccess': return { component: 'chatting-success', props: { ...preTextSuccessProps } }
        case 'chat': return { component: 'chatting-messages' }
        case 'chatWaiting': return { component: 'chatting-messages', props: { ...chatWaitingProps } }
        case 'chatMessagesOnly': return { component: 'chatting-messages', props: { ...chatMessagesOnlyProps } }
        default: return { component: 'chatting-welcome' }
      }
    })

    return {
      showPopover,
      chattingComponent,
    }
  },
})

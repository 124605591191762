import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chatting_call = _resolveComponent("chatting-call")!

  return (_openBlock(), _createBlock(_component_chatting_call, {
    "top-title": "Chat on WhatsApp",
    "top-subtitle": "Request help via WhatsApp chat",
    title: "Message us on WhatsApp",
    subtitle: "Сlick the button below or scan the QR code to send a message to this WhatsApp number.",
    "btn-text": "Open chat",
    "hide-note": "",
    "show-whatsapp-icon": "",
    "qr-code-label": "Scan QR code to open a chat",
    "qr-code": require('@/assets/images/byoc/qr-code.png')
  }, null, 8, ["qr-code"]))
}
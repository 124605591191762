
import { defineComponent, ref } from 'vue'
import ChattingPowered from '@/components/pages/chattingWidget/ChattingPowered.vue'
import useWidget from '@/compositions/chatWidget'

type ChattingWelcomeButton = {
  icon?: string;
  title: string;
  image?: string;
  text: string;
  clickEvent: () => void;
}

export default defineComponent({
  components: {
    ChattingPowered,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
  },
  setup() {
    const { updateCurrentState, widgetAppearanceSettings } = useWidget()
    const buttons = ref<ChattingWelcomeButton[]>([
      {
        icon: 'tmi-message-bubble',
        title: 'Chat now',
        text: 'Get instant help via chat',
        clickEvent: () => {
          updateCurrentState('preChat')
        },
      },
      {
        icon: 'email',
        title: 'Send an email',
        text: 'Log an email ticket',
        clickEvent: () => {
          updateCurrentState('email')
        },
      },
      {
        icon: 'phone',
        title: 'Call us',
        text: 'Speak with our agent now',
        clickEvent: () => {
          updateCurrentState('makeCall')
        },
      },
      {
        icon: 'chat',
        title: 'Send a text',
        text: 'Request help via SMS',
        clickEvent: () => {
          updateCurrentState('preText')
        },
      },
      {
        image: require('@/assets/images/chats/whatsapp.svg'),
        title: 'Chat on WhatsApp',
        text: 'Request help via WhatsApp chat',
        clickEvent: () => {
          updateCurrentState('whatsapp')
        },
      },
    ])

    return {
      buttons,
      widgetAppearanceSettings,
    }
  },
})

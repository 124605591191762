
import { defineComponent, ref } from 'vue'
import ChattingContainer from '@/components/pages/chattingWidget/ChattingContainer.vue'
import useWidget from '@/compositions/chatWidget'
import ChattingContentButton from '@/components/pages/chattingWidget/ChattingContentButton.vue'

export default defineComponent({
  components: {
    ChattingContentButton,
    ChattingContainer,
  },
  props: {
    containerTitle: {
      type: String,
    },
    containerSubtitle: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  setup() {
    const { bindChat, startNewChat, signup } = useWidget()
    const value = ref<any>(signup.value.email || signup.value.phone)
    const closeWidgetWindow = () => {
      bindChat()
      setTimeout(() => {
        startNewChat()
      }, 500)
    }
    return {
      value,
      closeWidgetWindow,
      startNewChat,
    }
  },
})

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-325bcad1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-welcome__title" }
const _hoisted_2 = { class: "body-text-medium-14 lh-20 opacity-80" }
const _hoisted_3 = { class: "chatting-welcome__buttons" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "chatting-welcome__icon" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex-grow-1 ml-4" }
const _hoisted_8 = { class: "subhead-semi-bold-16 lh-24 emphasize--text" }
const _hoisted_9 = { class: "body-text-regular-14 lh-20 distinct--text" }
const _hoisted_10 = { class: "chatting-welcome__chevron" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_chatting_powered = _resolveComponent("chatting-powered")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chatting-welcome", {
      'chatting-welcome--fullscreen': _ctx.fullscreen,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["chatting-welcome__top", {
        [`chatting-welcome__top--${_ctx.widgetAppearanceSettings.primaryColor}`]: true,
        'chatting-welcome__top--gradient': _ctx.widgetAppearanceSettings.isGradient,
      }])
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.widgetAppearanceSettings.title), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.widgetAppearanceSettings.subtitle), 1)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "chatting-welcome__button",
          onClick: item.clickEvent
        }, [
          _createElementVNode("div", _hoisted_5, [
            (!item.image)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: item.icon,
                  size: "xLarge",
                  class: _normalizeClass([`chatting-welcome__icon--${_ctx.widgetAppearanceSettings.primaryColor}`])
                }, null, 8, ["name", "class"]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: item.image,
                  class: "w20px"
                }, null, 8, _hoisted_6))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.text), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_tm_icon, {
              name: "chevron_right",
              size: "xLarge",
              class: "distinct--text"
            })
          ])
        ], 8, _hoisted_4))
      }), 128))
    ]),
    _createVNode(_component_chatting_powered)
  ], 2))
}
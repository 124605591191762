import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-792749f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-messages-list" }
const _hoisted_2 = { class: "chatting-messages-list-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_log_item = _resolveComponent("chat-log-item")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_scrollbar, {
        ref: "scrollbar",
        class: "h100pr",
        "content-bottom": "",
        "hide-scrollbar": ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesLocal, (message, i) => {
            return (_openBlock(), _createBlock(_component_chat_log_item, _mergeProps({ key: i }, message, {
              widget: "",
              contact: _ctx.contact,
              fullscreen: _ctx.fullscreen,
              class: {
            [`chatting-messages-list__item-outcoming--${_ctx.widgetAppearanceSettings.primaryColor}`]: message?.outcoming
          },
              onReply: ($event: any) => (_ctx.$emit('reply', message))
            }), null, 16, ["contact", "fullscreen", "class", "onReply"]))
          }), 128)),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 512)
    ])
  ]))
}
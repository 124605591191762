
import type { PropType } from 'vue'
import { defineComponent, nextTick, ref, onMounted, watch } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import ChatLogItem from '@/components/shared/chat/ChatLogItem.vue'
import type { ChatLogItemType } from '@/definitions/shared/chat/types'
import type { PersonType } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import useWidget from '@/compositions/chatWidget'
import type { PermanentModeType } from '@/definitions/chats/settings/web-widget/types'

const messages: ChatLogItemType[] = getTableData('widgetChat')[0].chatWidgetLog

export default defineComponent({
  components: {
    TmScrollbar,
    ChatLogItem,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
    waiting: {
      type: Boolean,
    },
    isTyping: {
      type: Boolean,
    },
    contact: {
      type: Object as PropType<PersonType>,
    },
    permanentMode: {
      type: String as PropType<PermanentModeType>,
      default: 'default',
    },
  },
  emits: ['reply'],
  setup(props) {
    const { popoverStatus, signup, rateStatus, rateMessage, widgetAppearanceSettings } = useWidget()
    const scrollbar = ref()
    const messagesLocal = ref<any>([
      {
        type: 'preChatForm',
        date: '2022-04-10T00:14:26.429Z',
        messageStatus: 'read',
        outcoming: true,
        sender: {
          email: signup.value.email,
          fullName: signup.value.name,
        },
        text: signup.value.message,
      },
      {
        type: 'message',
        date: '2022-04-10T00:14:26.429Z',
        text: 'Thank you, we have received your request.',
        serviceSender: true,
        outcoming: false,
      },
    ])
    const updateScrollbar = (value: number) => {
      nextTick(() => {
        if (scrollbar.value) {
          scrollbar.value.$el.scrollTop = value
        }
      })
    }

    const setScrollbarOnStart = () => {
      updateScrollbar(scrollbar.value?.$el?.scrollHeight)
    }

    if (props.permanentMode === 'messages-only') {
      messagesLocal.value.push(...messages)
    } else if (props.permanentMode === 'default') {
      watch(
        () => props.waiting,
        (newValue) => {
          if (!newValue) {
            messagesLocal.value.push({
              type: 'action',
              text: [
                {
                  text: props.contact?.fullName,
                  style: 'semi-bold',
                },
                ' joined chat',
              ],
              date: '2022-04-10T00:14:26.429Z',
            })

            setScrollbarOnStart()

            setTimeout(() => {
              messagesLocal.value.push(
                ...messages,
                {
                  type: 'action',
                  text: [
                    {
                      text: props.contact?.fullName,
                      style: 'semi-bold',
                    },
                    ' left chat',
                  ],
                  date: '2022-04-10T00:14:26.429Z',
                }
              )
              setTimeout(() => {
                setScrollbarOnStart()
              }, 500)
            }, 4000)
          }
        }
      )
    }

    watch(
      () => popoverStatus.value,
      (newValue) => {
        if (newValue === 'rate') {
          messagesLocal.value.push({
            type: 'action',
            text: [
              {
                text: 'You',
                style: 'semi-bold',
              },
              ' ended chat',
            ],
            date: '2022-04-10T00:14:28.429Z',
          })
        } else if (newValue === 'feedback') {
          messagesLocal.value.push({
            type: 'rate',
            rateStatus: rateStatus.value,
          })
        }

        setScrollbarOnStart()
      }
    )

    watch(
      () => rateMessage.value,
      (newValue) => {
        messagesLocal.value.push({
          type: 'feedback',
          rateStatus: rateStatus.value,
          text: newValue,
        })

        setScrollbarOnStart()
      }
    )

    onMounted(() => {
      setScrollbarOnStart()
    })

    return {
      messagesLocal,
      scrollbar,
      widgetAppearanceSettings,
    }
  },
})

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1172aac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-0 mr-3" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "subhead-semi-bold-16 lh-24 white--text" }
const _hoisted_4 = { class: "body-text-medium-14 lh-20" }
const _hoisted_5 = { class: "chatting-container-top__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chatting-container-top", {
      'chatting-container-top--fullscreen': _ctx.fullscreen,
      [`chatting-container-top--${_ctx.widgetAppearanceSettings.primaryColor}`]: true,
      'chatting-container-top--gradient': _ctx.widgetAppearanceSettings.isGradient,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.preload)
        ? (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 0,
            type: "circle",
            width: "40px",
            height: "40px",
            dark: ""
          }))
        : _renderSlot(_ctx.$slots, "avatar", { key: 1 }, () => [
            _createVNode(_component_tm_button, {
              flat: "",
              "icon-only": "",
              color: "transparent",
              class: "mx-n1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCurrentState('welcome')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "chevron_left",
                  size: "xLarge",
                  class: "white--text"
                })
              ]),
              _: 1
            })
          ], true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.preload)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_tm_skeleton, {
              width: "130px",
              height: "15px",
              dark: ""
            }),
            _createVNode(_component_tm_skeleton, {
              width: "106px",
              height: "8px",
              class: "mt-6px",
              dark: ""
            })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.hideFullscreenButton && !_ctx.fullscreen)
        ? (_openBlock(), _createBlock(_component_tm_button, {
            key: 0,
            flat: "",
            "icon-only": "",
            color: "transparent",
            onClick: _ctx.expandChat
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "tmi-open-fullscreen",
                class: "white--text",
                size: "large"
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.hideEndButton)
        ? (_openBlock(), _createBlock(_component_tm_button, {
            key: 1,
            flat: "",
            "icon-only": "",
            color: "transparent",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateShowPopover(true)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "close",
                class: "white--text",
                size: "large"
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
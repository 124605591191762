
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChattingContainer from '@/components/pages/chattingWidget/ChattingContainer.vue'
import ChattingContentButton from '@/components/pages/chattingWidget/ChattingContentButton.vue'

export default defineComponent({
  components: {
    TmButton,
    ChattingContentButton,
    ChattingContainer,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
  },
})

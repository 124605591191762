import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15c3b4ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-popover" }
const _hoisted_2 = { class: "chatting-popover-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chatting-popovers", {
      'chatting-popovers--fullscreen': _ctx.fullscreen
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), { fullscreen: _ctx.fullscreen }, null, 8, ["fullscreen"]))
      ])
    ])
  ], 2))
}

import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: { TmButton },
  props: {
    outline: {
      type: Boolean,
    },
  },
  setup() {
    const { widgetAppearanceSettings } = useWidget()
    return {
      widgetAppearanceSettings,
    }
  },
})

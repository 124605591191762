
import { defineComponent } from 'vue'
import ChattingPowered from '@/components/pages/chattingWidget/ChattingPowered.vue'
import ChattingContainerTop from '@/components/pages/chattingWidget/ChattingContainerTop.vue'

export default defineComponent({
  components: {
    ChattingPowered,
    ChattingContainerTop,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    fullscreen: {
      type: Boolean,
    },
    preload: {
      type: Boolean,
    },
    hideEndButton: {
      type: Boolean,
    },
    hideFullscreenButton: {
      type: Boolean,
    },
  },
})


import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChattingPopoversEnd from '@/components/pages/chattingWidget/chattingPopovers/ChattingPopoversEnd.vue'
import ChattingPopoversRate from '@/components/pages/chattingWidget/chattingPopovers/ChattingPopoversRate.vue'
import ChattingPopoversFeedback from '@/components/pages/chattingWidget/chattingPopovers/ChattingPopoversFeedback.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
    ChattingPopoversEnd,
    ChattingPopoversRate,
    ChattingPopoversFeedback,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
  },
  setup() {
    const { popoverStatus } = useWidget()
    const componentNames: Record<any, any> = {
      end: ChattingPopoversEnd,
      rate: ChattingPopoversRate,
      feedback: ChattingPopoversFeedback,
      hideRate: null,
    }
    const componentName = computed<any>(() => componentNames[popoverStatus.value])

    return {
      componentName,
    }
  },
})


import { defineComponent } from 'vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { singleWindow } from '@/services/singleWindow'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
    TmSkeleton,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    fullscreen: {
      type: Boolean,
    },
    preload: {
      type: Boolean,
    },
    hideEndButton: {
      type: Boolean,
    },
    hideFullscreenButton: {
      type: Boolean,
    },
  },
  setup() {
    const { bindChat, updateCurrentState, updateShowPopover, widgetAppearanceSettings } = useWidget()
    const expandChat = () => {
      bindChat()
      singleWindow(800, 800, '/chat-widget')
    }

    return {
      expandChat,
      updateCurrentState,
      updateShowPopover,
      widgetAppearanceSettings,
    }
  },
})

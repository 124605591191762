import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e005f4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center neutral--text mb-6" }
const _hoisted_2 = { class: "page-title-20 emphasize--text mb-2" }
const _hoisted_3 = { class: "body-text-regular-14 lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_chatting_content_button = _resolveComponent("chatting-content-button")!
  const _component_validation = _resolveComponent("validation")!
  const _component_chatting_container = _resolveComponent("chatting-container")!

  return (_openBlock(), _createBlock(_component_chatting_container, {
    title: _ctx.containerTitle,
    subtitle: _ctx.containerSubtitle,
    fullscreen: _ctx.fullscreen,
    "hide-fullscreen-button": "",
    "hide-end-button": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["prechatting-form", {
        'prechatting-form--fullscreen': _ctx.fullscreen
      }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1)
        ]),
        _createVNode(_component_validation, {
          onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitPreChat(_ctx.signup)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_form_line, {
              label: "Name",
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_field, {
                  modelValue: _ctx.signup.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signup.name) = $event)),
                  placeholder: "Enter your full name",
                  name: "Name",
                  autofocus: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_ctx.phoneForm)
              ? (_openBlock(), _createBlock(_component_tm_form_line, {
                  key: 0,
                  label: "Your mobile number",
                  required: "",
                  class: "mb-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_tm_field, {
                      modelValue: _ctx.signup.phone,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signup.phone) = $event)),
                      validators: "required",
                      placeholder: "Enter your mobile number",
                      "error-hint": "Please enter your mobile number",
                      name: "Mobile number"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_tm_form_line, {
                  key: 1,
                  label: "Email",
                  required: "",
                  class: "mb-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_tm_field, {
                      modelValue: _ctx.signup.email,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.signup.email) = $event)),
                      validators: "required|email",
                      placeholder: "Enter your email address",
                      "error-hint": "Please enter your email address",
                      name: "Email"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })),
            _createVNode(_component_tm_form_line, {
              label: "Message",
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_field, {
                  modelValue: _ctx.signup.message,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.signup.message) = $event)),
                  type: "textarea",
                  validators: "required",
                  placeholder: "How can we help you today?",
                  name: "Message"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_chatting_content_button, {
              size: "xLarge",
              type: "submit",
              class: "prechatting-form__btn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnText), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["title", "subtitle", "fullscreen"]))
}

import type { PropType } from 'vue'
import { defineComponent, ref, computed } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import ChattingMessagesList from '@/components/pages/chattingWidget/ChattingMessagesList.vue'
import ChattingContainer from '@/components/pages/chattingWidget/ChattingContainer.vue'
import ChattingPreload from '@/components/pages/chattingWidget/ChattingPreload.vue'
import type { ChatLogItemType, FieldEditorRepliedMessage } from '@/definitions/shared/chat/types'
import type { PersonType } from '@/definitions/shared/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import DragAndDrop from '@/components/shared/templates/DragAndDrop.vue'
import useWidget from '@/compositions/chatWidget'
import type { PermanentModeType } from '@/definitions/chats/settings/web-widget/types'
import ChattingContentButton from '@/components/pages/chattingWidget/ChattingContentButton.vue'

export default defineComponent({
  components: {
    ChattingContentButton,
    DragAndDrop,
    TmAvatar,
    TmButton,
    TmSpinner,
    ChattingMessagesList,
    ChattingContainer,
    ChattingPreload,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
    serviceSender: {
      type: Boolean,
    },
    permanentMode: {
      type: String as PropType<PermanentModeType>,
      default: 'default',
    },
  },
  setup(props) {
    const { updateShowPopover, startNewChat, popoverStatus, rateMessage, widgetAppearanceSettings } = useWidget()
    const preload = ref(true)
    const waiting = ref(true)
    const isTyping = ref(false)
    const preloadDelay = () => {
      if (props.permanentMode === 'waiting') {
        preload.value = false
        waiting.value = true
        isTyping.value = false
      } else if (props.permanentMode === 'messages-only') {
        preload.value = false
        waiting.value = false
        isTyping.value = false
      } else {
        setTimeout(() => {
          preload.value = false
        }, 4000)

        setTimeout(() => {
          waiting.value = false
        }, 8000)
        setTimeout(() => {
          isTyping.value = true
        }, 9000)
        setTimeout(() => {
          isTyping.value = false
        }, 12000)
      }
    }

    preloadDelay()

    const dragAndDropVisible = ref(false)
    const editor = ref()

    const btnsList = [
      editorBtns.emoji,
      editorBtns.attachment,
    ]

    const addAttachment = (e: DragEvent) => {
      dragAndDropVisible.value = false
      editor.value.field.dragAndDrop(e)
    }
    const contact = ref<PersonType>({
      uid: '1',
      firstName: 'Markus',
      lastName: 'Harris',
      fullName: 'Markus Harris',
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
      avatarColor: 'blue',
      status: 'online',
    })

    const title = computed(() => (waiting.value || popoverStatus.value !== 'end') ? 'Live chat' : contact.value.fullName)
    const subtitle = computed(() => waiting.value ? 'Searching for agents...' : (popoverStatus.value !== 'end' ? 'Chat has ended' : 'Support agent'))
    const messageValue = ref('')
    const repliedMessage = ref<FieldEditorRepliedMessage | null>(null)
    const onReply = (message: ChatLogItemType) => {
      repliedMessage.value = {
        name: message.outcoming ? 'John Snow' : 'Markus Harris',
        text: message.text,
        blue: !!message.outcoming,
      }
    }

    const showFeedbackButton = computed(() => ((popoverStatus.value === 'feedback') && !rateMessage.value) || popoverStatus.value === 'rate')

    return {
      preload,
      waiting,
      isTyping,
      contact,
      title,
      subtitle,
      messageValue,
      dragAndDropVisible,
      editor,
      btnsList,
      addAttachment,
      repliedMessage,
      onReply,
      updateShowPopover,
      popoverStatus,
      startNewChat,
      rateMessage,
      showFeedbackButton,
      widgetAppearanceSettings,
    }
  },
})

<template>
  <div class="chatting-powered">
    <span>Powered by</span>
    <img
      src="@/assets/images/logo-with-text.svg"
      class="ml-1"
    >
  </div>
</template>

<style lang="scss" scoped>
.chatting-powered {
  padding: 5px;
  text-align: center;
  min-height: 26px;
  font-size: 11px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light;
  font-weight: $semi-bold-font;
  img {
    height: 12px;
  }
}
</style>


import { defineComponent, ref, watch, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { ratePopoverFeedbackGood, ratePopoverFeedbackBad } from '@/definitions/chats/settings/web-widget/data'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
  },
  setup() {
    const { updateShowPopover, updateRateMessage, rateStatus, rateMessage } = useWidget()
    const rateMessageValue = ref(rateMessage.value)

    const rate = computed(() => rateStatus.value === 'good' ? ratePopoverFeedbackGood : ratePopoverFeedbackBad)

    watch(
      () => rateMessage.value,
      (newValue) => {
        rateMessageValue.value = newValue
      }
    )

    return {
      updateShowPopover,
      rateMessageValue,
      rate,
      updateRateMessage,
    }
  },
})


import { Form as Validation } from 'vee-validate'
import { defineComponent } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import ChattingContainer from '@/components/pages/chattingWidget/ChattingContainer.vue'
import useWidget from '@/compositions/chatWidget'
import ChattingContentButton from '@/components/pages/chattingWidget/ChattingContentButton.vue'

export default defineComponent({
  components: {
    ChattingContentButton,
    TmFormLine,
    Validation,
    ChattingContainer,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
    containerTitle: {
      type: String,
    },
    containerSubtitle: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    btnText: {
      type: String,
    },
    phoneForm: {
      type: Boolean,
    },
  },
  setup() {
    const { updateCurrentState, updateSignup, currentState, signup, widgetAppearanceSettings } = useWidget()
    const submitPreChat = (newValue: any) => {
      updateSignup(newValue)
      switch (currentState.value) {
        case 'email': return updateCurrentState('emailSuccess')
        case 'preText': return updateCurrentState('preTextSuccess')
        default: return updateCurrentState('chat')
      }
    }

    return {
      signup,
      submitPreChat,
      widgetAppearanceSettings,
    }
  },
})

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-059bdcdf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-container__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chatting_container_top = _resolveComponent("chatting-container-top")!
  const _component_chatting_powered = _resolveComponent("chatting-powered")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chatting-container", {
      'chatting-container--fullscreen': _ctx.fullscreen
    }])
  }, [
    _createVNode(_component_chatting_container_top, {
      title: _ctx.title,
      subtitle: _ctx.subtitle,
      fullscreen: _ctx.fullscreen,
      preload: _ctx.preload,
      "hide-end-button": _ctx.hideEndButton,
      "hide-fullscreen-button": _ctx.hideFullscreenButton
    }, {
      avatar: _withCtx(() => [
        _renderSlot(_ctx.$slots, "avatar", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["title", "subtitle", "fullscreen", "preload", "hide-end-button", "hide-fullscreen-button"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_chatting_powered)
  ], 2))
}

import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
  },
  emits: ['update:feedbackRate'],
  setup() {
    const { updateShowPopover, updateRateStatus, updatePopoverStatus } = useWidget()
    const rateGood = () => {
      updateRateStatus('good')
      updatePopoverStatus('feedback')
    }
    const rateBad = () => {
      updateRateStatus('bad')
      updatePopoverStatus('feedback')
    }

    return {
      updateShowPopover,
      rateGood,
      rateBad,
    }
  },
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cfa7080"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-preload" }
const _hoisted_2 = { class: "flex-grow-1 w100pr d-flex align-end justify-end flex-column" }
const _hoisted_3 = { class: "w100pr d-flex justify-center mb-5" }
const _hoisted_4 = { class: "chatting-preload__field" }
const _hoisted_5 = { class: "flex-grow-1 w100pr" }
const _hoisted_6 = { class: "d-flex align-center justify-space-between w100pr" }
const _hoisted_7 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_skeleton, {
        width: "220px",
        height: "60px",
        class: "mb-4"
      }),
      _createVNode(_component_tm_skeleton, {
        width: "168px",
        height: "40px",
        class: "mb-5"
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tm_skeleton, {
          width: "202px",
          height: "10px"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_tm_skeleton, {
          width: "132px",
          height: "10px"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
            return _createVNode(_component_tm_skeleton, {
              key: i,
              type: "circle",
              width: "20px",
              height: "20px",
              class: "mr-4"
            })
          }), 64))
        ]),
        _createVNode(_component_tm_skeleton, {
          type: "circle",
          width: "20px",
          height: "20px"
        })
      ])
    ])
  ]))
}
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chatting_popovers = _resolveComponent("chatting-popovers")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.chattingComponent.component), _mergeProps({ fullscreen: _ctx.fullscreen }, _ctx.chattingComponent.props), null, 16, ["fullscreen"])),
    (_ctx.showPopover)
      ? (_openBlock(), _createBlock(_component_chatting_popovers, {
          key: 0,
          fullscreen: _ctx.fullscreen
        }, null, 8, ["fullscreen"]))
      : _createCommentVNode("", true)
  ]))
}

import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { updateShowPopover, updatePopoverStatus, signup } = useWidget()
    return {
      signup,
      updateShowPopover,
      updatePopoverStatus,
    }
  },
})


import { defineComponent } from 'vue'
import ChattingContainer from '@/components/pages/chattingWidget/ChattingContainer.vue'
import ChattingContentButton from '@/components/pages/chattingWidget/ChattingContentButton.vue'

export default defineComponent({
  components: {
    ChattingContentButton,
    ChattingContainer,
  },
  props: {
    fullscreen: {
      type: Boolean,
    },
    topTitle: {
      type: String,
      default: 'Make a call',
    },
    topSubtitle: {
      type: String,
      default: 'Speak with our agent now',
    },
    title: {
      type: String,
      default: 'Speak with an agent',
    },
    subtitle: {
      type: String,
      default: 'Have an urgent matter? Please call us, and a dedicated agent will be available to help you.',
    },
    btnText: {
      type: String,
      default: 'Call now',
    },
    hideNote: {
      type: Boolean,
    },
    qrCodeLabel: {
      type: String,
      default: 'Scan QR code for quick dial',
    },
    qrCode: {
      type: String,
      default: require('@/assets/images/qr-code2.png'),
    },
    showWhatsappIcon: {
      type: Boolean,
    },
  },
})
